<template>
  <div class="workplace-file">
    <div class="flex-row flex-start wrap-header">
      <el-row style="width: 100%">
        <el-col :span="2" style="text-align: center">
          <img src="@/assets/images/组 16.png" />
        </el-col>
        <el-col
          :span="6"
          style="text-align: center; font-weight: 600; font-size: 1.25rem"
        >
          <span>{{
            !cpu_paramsList.length ? "当前暂无订阅" : "您好！您当前选择的是："
          }}</span>
        </el-col>
        <el-col :span="16">
          <div class="filterItem-params-left" style="font-size: 16px">
            <span
              v-if="!cpu_paramsList.length"
              style="margin-left: 1.25rem"
            ></span>
            <a v-else class="pad-rl-1 search-params-clean" @click="cleanParams"
              >清空</a
            >
            <span
              style="color: #ff890b; margin-left: 2.5rem; margin-left: 1.25rem"
              v-for="(item, index) in cpu_paramsList"
              :key="index"
            >
              {{ item.label }}&nbsp;&nbsp;&nbsp;
            </span>
          </div>
        </el-col>
      </el-row>
      <div style="text-align: center">
        <el-button
          type="primary"
          style="margin: 1rem 0; width: 6rem"
          @click="dialogVisible = true"
          >重新订阅</el-button
        >
        <el-button
          @click="close()"
          v-if="index == 1"
          type="danger"
          style="width: 6rem; margin: 0"
          >返回列表</el-button
        >
      </div>
    </div>
    <div class="flex-column-between collection-container">
      <el-table
        :data="policyList"
        style="width: 100%; font-size: 18px"
        v-if="index == 0"
      >
        <el-table-column prop="date" label="政策标题" width="800">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.name"
                placement="top"
              >
                <span
                  @click="open(scope.row.content)"
                  style="cursor: pointer"
                  >{{ scope.row.name | ellipsisE }}</span
                >
              </el-tooltip>
              <span class="isnew" v-show="SameMonth(scope.row.publictime)"></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="发布部门" width="280">
          <template slot-scope="scope">
            <span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.deptName"
                placement="top"
              >
                <span>{{ scope.row.deptName | ellipsis }}</span>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="province" width="200">
          <template slot="header">
            <el-row style="width: 100%; text-align: center">
              <el-col :span="12" style="padding-top: 10px">
                <span>发布时间</span>
              </el-col>
              <el-col :span="12">
                <el-button type="primary" style="" @click="export2Excel()"
                  >导出</el-button
                >
              </el-col>
            </el-row>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.publictime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="index == 1"
        style="
          background-color: #fff;
          padding: 10px 20px;
          height: 33.5rem;
          overflow-y: auto;
        "
      >
        <span v-html="content" style="font-size: 18px"></span>
      </div>
    </div>
    <el-dialog
      title="订阅标签"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <el-row class="rows">
        <span style="font-size: 1.375rem; font-weight: blod; color: #000"
          >订阅周期</span
        >
        <el-col :span="23" :offset="1" style="margin-top: 1rem">
          <el-date-picker
            v-model="subscriptionCycle"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row class="rows">
        <span style="font-size: 1.375rem; font-weight: blod; color: #000"
          >行业部门</span
        >
        <FilterGroup @change="changeSearch">
          <FilterCheckBox
            v-model="searchInfo.data.hydl"
            :list="hydlList"
            lable="行业大类"
            valueKey="deptCode"
            labelKey="deptName"
            :needAll="false"
            ref="hydl"
            :checkLists="yddlList"
            fold
          ></FilterCheckBox>
        </FilterGroup>
      </el-row>
      <el-row class="rows">
        <span style="font-size: 1.375rem; font-weight: blod; color: #000"
          >政策关键字</span
        >
        <FilterGroup @change="changeSearch">
          <FilterCheckBox
            v-model="searchInfo.data.zcbq"
            :list="zcpqList"
            lable="政策关键字"
            valueKey="labelKey"
            labelKey="labelValue"
            :needAll="false"
            ref="zcbq"
            :checkLists="ydzcList"
            fold
          ></FilterCheckBox>
        </FilterGroup>
      </el-row>
      <el-row class="rows">
        <span style="font-size: 1.375rem; font-weight: blod; color: #000"
          >行业小类</span
        >
        <FilterGroup @change="changeSearch">
          <FilterCheckBox
            v-model="searchInfo.data.hyxl"
            :list="hyxlList"
            lable="行业小类"
            valueKey="labelcode"
            labelKey="labelname"
            :needAll="false"
            ref="hyxl"
            :checkLists="ydxlList"
            fold
          ></FilterCheckBox>
        </FilterGroup>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="success" @click="handleOnok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const ltime = new Date().toLocaleDateString();
import { mapGetters } from "vuex";
import { FilterGroup, FilterCheckBox } from "../filter/index.js";
import moment from "moment";
export default {
  components: {
    FilterGroup,
    FilterCheckBox,
  },
  data() {
    return {
      dialogVisible: false,
      index: "0",
      content: "",
      subscriptionCycle: "",
      hyxlList: [], //行业小类
      hydlList: [], //行业大类
      zcpqList: [], //政策关键字
      policyList: [],
      searchInfo: {
        data: {
          hydl: "", //行业大类
          zcbq: "", // 政策关键字
          hyxl: "", // 行业小类
        },
      },
      searchDataHydl: "", //搜索行业大类需要的数据
      searchDataZcbq: "", //搜索政策标签需要的数据
      searchDataHyxl: "", //搜索行业小类需要的数据
      searchDataHydlList: "", //搜索行业大类需要的数据
      searchDataZcbqList: "", //搜索政策标签需要的数据
      searchDataHyxlList: "", //搜索行业小类需要的数据
      ydxlList: [],
      yddlList: [],
      ydzcList: [],
      ltime,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
    }),
    /**
     * @description: 已选择的搜索条件列表
     * @return {Array}
     */
    // cpu_noticeList() {
    //   return this.noticeList.slice(0, 4);
    // },
    cpu_paramsList() {
      let list = [];
      // 行业大类
      if (this.searchDataHydl) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataHydl !== "") {
          arrData = this.searchDataHydl.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.hydlList.find(
              (cv) => cv.deptCode === item
            )?.deptName;
            listlabel.push(label);
          });
          list.push({
            type: "hydl",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 政策关键字
      if (this.searchDataZcbq) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataZcbq !== "") {
          arrData = this.searchDataZcbq.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.zcpqList.find(
              (cv) => cv.labelKey === item
            )?.labelValue;
            listlabel.push(label);
          });
          list.push({
            type: "zcbq",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 行业小类
      if (this.searchDataHyxl) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataHyxl !== "") {
          arrData = this.searchDataHyxl.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.hyxlList.find(
              (cv) => cv.labelcode === item
            )?.labelname;
            listlabel.push(label);
          });
          list.push({
            type: "hyxl",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 去掉第一个逗号
      list.filter((item) => {
        if (item.label[0] == "，") {
          item.label = item.label.substring(1);
        }
      });
      return list;
    },
  },
  created() {
    this.init();
    console.log(this.ltime);
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 13) {
        return value.slice(0, 13) + "...";
      }
      return value;
    },
    ellipsisE(value) {
      if (!value) return "";
      if (value.length > 40) {
        return value.slice(0, 40) + "...";
      }
      return value;
    },
  },
  methods: {
    // =================判断当前政策的发布时间是否在近一个月内=====================
    /* 判断是否在同一个月
    date:时间字符串
    return:true/false */
    SameMonth(date) {
      var date1 = new Date(date.replace(/-/g, "/")); //将传入的时间字符串转换成时间对象
      var date2 = new Date(); //当前时间
      var curDay = date2.getDate(); //获取当前几号
      var firstDay = this.GetDate(curDay, 1); //计算出当月第一天
      var lastDay = this.GetDate(this.getDaysInMonth(date2.getFullYear(), date2.getMonth() + 1) - curDay,2); //计算出当月最后一天
      if (
        date1.getTime() < firstDay.getTime() ||
        date1.getTime() > lastDay.getTime()
      ) {
        return false; //不在同一个月内
      } else {
        return true; //在同一个月内
      }
    },
    // 获取某年某月有多少天
    getDaysInMonth(year, month) {
      month = parseInt(month, 10) + 1;
      var temp = new Date(year + "/" + month + "/0");
      return temp.getDate();
    },
    //  获取当前日期前N天或后N日期(N = day)
    // type:1：前；2：后
    GetDate(day, type) {
      var zdate = new Date();
      var edate;
      if (type == 1) {
        edate = new Date(zdate.getTime() - day * 24 * 60 * 60 * 1000);
      } else {
        edate = new Date(zdate.getTime() + day * 24 * 60 * 60 * 1000);
      }
      return edate;
    },
    // =================判断当前政策的发布时间是否在近一个月内=====================
    init() {
      this.getAllLabel();
      this.queryLabelDept();
      this.queryPolicyKeyword();
      this.queryEnterpriseLabel();
    },
    open(content) {
      this.index = "1";
      this.content = content;
    },
    close() {
      this.index = "0";
    },
    queryEnterpriseLabel() {
      let url = "/dev-api/enterprise-label/queryEnterpriseLabel";
      let params = {
        tyshxydm: this.userInfo.enterpriseInfo.tyshxydm,
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.searchDataHydlList = res.data.deptList;
          this.searchDataZcbqList = res.data.policyKeywordList;
          this.searchDataHyxlList = res.data.labelList;
          this.queryPolicyByLabel();
          res.data.labelList.forEach((item, index) => {
            this.searchDataHyxl = this.searchDataHyxl + item.labelKey;
            if (index < res.data.labelList.length - 1) {
              this.searchDataHyxl = this.searchDataHyxl + ",";
            }
            this.hyxlList.forEach((items, index) => {
              if (item.labelKey === items.labelcode) {
                this.ydxlList.push(index);
              }
            });
          });
          res.data.deptList.forEach((item, index) => {
            this.searchDataHydl = this.searchDataHydl + item.labelKey;
            if (index < res.data.deptList.length - 1) {
              this.searchDataHydl = this.searchDataHydl + ",";
            }
            this.hydlList.forEach((items, index) => {
              if (item.labelKey === items.deptCode) {
                this.yddlList.push(index);
              }
            });
          });
          res.data.policyKeywordList.forEach((item, index) => {
            this.searchDataZcbq = this.searchDataZcbq + item.labelKey;
            if (index < res.data.policyKeywordList.length - 1) {
              this.searchDataZcbq = this.searchDataZcbq + ",";
            }
            this.zcpqList.forEach((items, index) => {
              if (item.labelKey === items.labelKey) {
                this.ydzcList.push(index);
              }
            });
          });
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
    getAllLabel() {
      let url = "/dev-api/enterprise-label/queryAllLabel";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.hyxlList = res.data;
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
    queryPolicyKeyword() {
      let url = "/dev-api/enterprise-label/queryPolicyKeyword";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.zcpqList = res.data;
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
    queryLabelDept() {
      let url = "/dev-api/enterprise-label/queryLabelDept";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.hydlList = res.data;
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleOnok() {
      let list = [];
      this.searchDataHydlList = [];
      this.searchDataZcbqList = [];
      this.searchDataHyxlList = [];
      this.searchDataHyxl.split(",").forEach((item) => {
        let map = {};
        let label = this.hyxlList.find(
          (cv) => cv.labelcode === item
        )?.labelname;
        map.labelKey = item;
        map.labelValue = label;
        this.searchDataHyxlList.push(map);
        map.type = "2";
        list.push(map);
      });
      this.searchDataZcbq.split(",").forEach((item) => {
        let map = {};
        let label = this.zcpqList.find(
          (cv) => cv.labelKey === item
        )?.labelValue;
        map.labelKey = item;
        map.labelValue = label;
        this.searchDataZcbqList.push(map);
        map.type = "1";
        list.push(map);
      });
      this.searchDataHydl.split(",").forEach((item) => {
        let map = {};
        let label = this.hydlList.find((cv) => cv.deptCode === item)?.deptName;
        map.labelKey = item;
        map.labelValue = label;
        this.searchDataHydlList.push(map);
        map.type = "3";
        list.push(map);
      });
      let params = {
        enterpriseLabel: {
          enterpriseName: this.userInfo.enterpriseInfo.enterprise,
          enterpriseTyshxydm: this.userInfo.enterpriseInfo.tyshxydm,
          startTime: moment(this.subscriptionCycle[0]),
          finishTime: moment(this.subscriptionCycle[1]),
          enterpriseLabelList: list,
        },
      };
      this.$httpApi
        .post("/dev-api/enterprise-label/saveEnterpriseLabel", params)
        .then((res) => {})
        .catch((err) => {
          console.log("evaluate error:", err);
        });
      this.dialogVisible = false;
      this.queryPolicyByLabel();
    },
    cleanParams() {
      this.searchDataHydl = "";
      this.searchDataZcbq = "";
      this.searchDataHyxl = "";
      this.$refs.hydl.cleanBoxList();
      this.$refs.zcbq.cleanBoxList();
      this.$refs.hyxl.cleanBoxList();
    },
    //导出excel
    export2Excel() {
      require.ensure([], () => {
        const {
          export_json_to_excel,
        } = require("../../../../vendor/Export2Excel");
        const tHeader = ["政策标题", "发布部门", "发布时间"];
        // 上面设置Excel的表格第一行的标题
        const filterVal = ["name", "deptName", "createtime"];
        // 上面的lockCode、keyCode、quotationNumber,terminal,createTime,operType,userName是dataList里对象的属性
        const list = this.policyList; //把data里的dataList存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "政策列表");
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    /**
     * @description: 修改搜索条件
     */
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      //     资金限额自定义时
      switch (label) {
        case "行业大类":
          this.searchDataHydl = value;
          break;
        case "政策关键字":
          this.searchDataZcbq = value;
          break;
        case "行业小类":
          this.searchDataHyxl = value;
          break;
        default:
          break;
      }
    },
    queryPolicyByLabel() {
      let params = {
        policyKeywordList: this.searchDataZcbqList,
        labelList: this.searchDataHyxlList,
        deptList: this.searchDataHydlList,
        sortBy: "1",
      };
      this.$httpApi
        .post("/dev-api/enterprise-label/queryPolicyByLabel", params)
        .then((res) => {
          this.policyList = res.data;
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.rows {
  margin: 1rem 0;
  padding: 1rem 0.5rem;
}
.workplace-file {
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight} - 3.75rem);
}
.wrap-header {
  padding: 0.9375rem 1.25rem;
  margin-bottom: 0.625rem;
  background-color: #ffffff;
}
::v-deep .el-range-separator {
  width: 10%;
}
::v-deep .el-table__body-wrapper {
  height: 29.6rem;
  overflow-y: auto;
}
.isnew {
  display: inline-block;
  margin-left: 10px;
  width: 36px;
  height: 36px;
  background-image: url("~@/assets/deyangImg/newIcon.png");
  background-size: cover;
  // color: red;
  // border-radius: 50%;
  // border: 1px solid red;
  // text-align: center;
}
</style>
